import React, { ChangeEvent, Component } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import '../../../public/stylesheets/homepage.css';

const getNameURL = '/api/generate-name';

interface HomepageUIState {
  gameName: string;
  fetchInProgress: boolean;
}

export class HomepageUI extends Component<any, HomepageUIState> {
  constructor(props: any) {
    super(props);
    this.state = {
      gameName: '',
      fetchInProgress: true
    };

    this.handleGetNewName = this.handleGetNewName.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleJoin = this.handleJoin.bind(this);
  }

  componentDidMount() {
    this.handleGetNewName();
  }

  async getNewName() {
    const response = await fetch(getNameURL);
    console.log(response);
    const name = await response.text();
    return name;
  }

  handleGetNewName() {
    this.setState({
      fetchInProgress: true
    });
    this.getNewName().then(name => {
      this.setState({
        gameName: name,
        fetchInProgress: false
      });
    });
  }

  handleChange(event: ChangeEvent<HTMLInputElement>) {
    this.setState({gameName: event.target.value});
  }

  handleJoin() {
    window.location.href = '/game/' + this.state.gameName;
  }

  render() {
    return (
      <div className='create-game'>
        <Box className='titleBox'>
          <Typography variant='h1'>~ Durak ~</Typography>
          <Typography variant='subtitle1'>"There's no winner, only a loser."</Typography>
          <Typography variant='body2'>
            [<Link href='https://en.wikipedia.org/wiki/Durak'>rules</Link>]
          </Typography>
        </Box>
        <Box className='roomNameBox'>
          <Grid container direction='row' justify='center' alignItems='baseline' spacing={1}>
            <Grid item xs={6}>
              <TextField
                helperText='choose a room name'
                onChange={this.handleChange}
                disabled={this.state.fetchInProgress}
                value={this.state.gameName}
                fullWidth
              />
            </Grid>
            <Grid item>
              <Button variant='contained' onClick={this.handleGetNewName}><RefreshIcon /></Button>
            </Grid>
            <Grid item>
              <Button variant='contained' color='primary' onClick={this.handleJoin}>Join Game</Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    );
  }
}